<template>
  <gf-base>
    <gf-card :title="title" :subtitle="subtitle">
      <el-dialog title="Remove Group" :visible.sync="removeModalVisible" append-to-body v-loading="removeModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
            </span>
          </template>
          You are trying to remove this group. User will also be removed from the group. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="removeModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Remove Group
          </gf-button>
        </template>
      </el-dialog>
      <template #toolbar>
        <gf-button v-if="$route.meta.mode === 'update' && group && group.id !== 1" class="mr-4" type="danger" @click="removeModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <el-row class="content" v-loading="loading">
        <el-col class="content__nav">
          <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 1 }" @click="active = 1">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 1 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Group Information</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Input group information</span>
            </div>
          </div>
          <!-- <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 2 }" @click="active = 2">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 2 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M7,11.4648712 L7,17 C7,18.1045695 7.8954305,19 9,19 L15,19 L15,21 L9,21 C6.790861,21 5,19.209139 5,17 L5,8 L5,7 L7,7 L7,8 C7,9.1045695 7.8954305,10 9,10 L15,10 L15,12 L9,12 C8.27142571,12 7.58834673,11.8052114 7,11.4648712 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <path d="M18,22 C19.1045695,22 20,21.1045695 20,20 C20,18.8954305 19.1045695,18 18,18 C16.8954305,18 16,18.8954305 16,20 C16,21.1045695 16.8954305,22 18,22 Z M18,24 C15.790861,24 14,22.209139 14,20 C14,17.790861 15.790861,16 18,16 C20.209139,16 22,17.790861 22,20 C22,22.209139 20.209139,24 18,24 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M18,13 C19.1045695,13 20,12.1045695 20,11 C20,9.8954305 19.1045695,9 18,9 C16.8954305,9 16,9.8954305 16,11 C16,12.1045695 16.8954305,13 18,13 Z M18,15 C15.790861,15 14,13.209139 14,11 C14,8.790861 15.790861,7 18,7 C20.209139,7 22,8.790861 22,11 C22,13.209139 20.209139,15 18,15 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Members</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Manage group members</span>
            </div>
          </div> -->
          <div class="nav__item text-hover-primary bg-hover-light text mb-2" :class="{ 'bg-light': active === 2 }" @click="active = 2">
            <span class="icon mr-2">
              <span class="svg-icon" :class="{ 'svg-icon-primary': active === 2 }">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                    <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                  </g>
                </svg>
              </span>
            </span>
            <div class="label">
              <span class="text-dark font-weight-bold font-size-sm mb-1">Permissions</span>
              <span class="font-weight-bold text-dark-50 font-size-xs">Setup groups permissions</span>
            </div>
          </div>
        </el-col>
        <el-col class="content__body">
          <gf-container>
            <gf-form :width="500" :style="{ display: active === 1 ? 'block': 'none' }">
              <el-form label-width="120px" ref="group" :model="group" :rules="groupRules">
                <gf-alert class="mb-8">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                          <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Input Group's information below
                </gf-alert>
                <el-form-item label="Group Name" prop="name">
                  <el-input v-model="group.name"></el-input>
                </el-form-item>
                <div class="control">
                  <gf-button type="primary" @click="$route.meta.mode === 'create' ? active++ : active+= 2 ">
                    <template slot="icon">
                      <span class="svg-icon svg-icon-white svg-icon-sx">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                          </g>
                        </svg>
                      </span>
                    </template>
                    Next
                  </gf-button>
                </div>
              </el-form>
            </gf-form>
            <div class="transfer" :style="{ display: active === 2 ? 'flex': 'none' }">
              <div style="width: 576px">
                <gf-alert class="mb-8">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                          <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Select Group's Permission below. You can control group permission here.
                  <br>
                  <b>Note:</b> Group Permission will be inherited by the members
                </gf-alert>
              </div>
              <el-transfer :titles="['Permissions', 'Selected Permissions']" v-model="selectedResources" :data="resources"></el-transfer>
              <div class="mt-5 control" style="width: 576px;">
                <gf-button type="secondary" @click="active--">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                          <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Back
                </gf-button>
                <gf-button class="ml-4" type="primary" @click="save" v-if="group && group.id !== 1">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-white svg-icon-sx">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                          <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Save
                </gf-button>
              </div>
            </div>
          </gf-container>
        </el-col>
      </el-row>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  height: 100%;

  &__nav {
    width: 35%;

    .nav__item {
      padding: 15px 20px;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      align-items: center;

      .label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.control {
  text-align: right;
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 5.46px;

  img {
    border: 3px solid #fff;
    width: 120px;
    height: 120px;
    box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.075);
    border-radius: 5.46px;
  }

  .tool {
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #B5B5C3 !important;
      font-size: 12px;
    }

    &__change {
      position: absolute;
      top: -7px;
      right: -8px;

      input {
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__remove {
      position: absolute;
      bottom: 7px;
      right: -8px;
    }
  }
}

.transfer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import ResourceService from '@/services/v1/Resource'
import GroupService from '@/services/v1/Group'

export default {
  data () {
    return {
      title: '',
      subtitle: '',

      removeModalVisible: false,
      removeModalLoading: false,

      group: {
        name: null
      },
      selectedResources: [],

      // fetch
      resources: [],

      groupRules: {
        name: [
          { required: true, message: 'Group Name is require', trigger: 'blur' }
        ]
      },

      // ui
      loading: false,

      active: 1
    }
  },
  methods: {
    // navigation
    back () {
      this.$router.back()
    },

    // main
    save () {
      this.$refs.group.validate()
        .then(async () => {
          try {
            this.loading = true

            const group = this.$_.cloneDeep(this.group)

            const resource = []
            for (let i = 0; i < this.selectedResources.length; i++) {
              const sresource = this.selectedResources[i]
              resource.push({ resource_id: sresource })
            }

            group.resource = resource

            const id = this.$route.params.id
            if (this.$route.meta.mode === 'create') {
              const gpService = new GroupService()
              await gpService.create(group)
              this.$message.success('Group was successfully added')
            } else {
              const gpService = new GroupService(id)
              await gpService.update(group)
              this.$message.success('Group was successfully modified')
            }

            this.$router.push({ name: 'groups-list' })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Failed',
            message: 'Please check required details.'
          })
        })
    },
    async remove () {
      try {
        this.removeModalLoading = true
        const gpService = new GroupService(this.$route.params.id)
        await gpService.del()

        this.$message.success('You have successfully deleted a group')
        this.removeModalVisible = false
        this.$router.push({ name: 'groups-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.removeModalLoading = false
      }
    },

    // fetch
    async getResources () {
      try {
        const rsService = new ResourceService()
        const response = await rsService.list({ size: 1000, number: 1 })

        for (let i = 0; i < response.data.rows.length; i++) {
          const rs = response.data.rows[i]
          this.resources.push({ key: rs.id, label: `${rs.code} (${rs.permission})` })
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    },
    async getGroup () {
      try {
        this.selectedResources = []

        const gpService = new GroupService(this.$route.params.id)
        const response = await gpService.get()

        this.group = response.data

        for (let i = 0; i < this.group.resources.length; i++) {
          const resource = this.group.resources[i]
          this.selectedResources.push(resource.id)
        }

        delete this.group.resources
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      }
    }
  },
  async mounted () {
    await this.getResources()

    if (this.$route.meta.mode === 'create') {
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Settings' },
        { title: 'Company Details' },
        { title: 'Groups' },
        { title: 'Create' }
      ])

      this.title = 'New Group'
      this.subtitle = 'Create New Group'
    } else {
      await this.getGroup()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Settings' },
        { title: 'Company Details' },
        { title: 'Groups' },
        { title: this.group.name }
      ])

      this.title = this.group.name
      this.subtitle = 'Modify Group Information'
    }
  }
}
</script>
